<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
          <template v-slot:preview>
            <b-row class="col-md-12 mb-1 m-0 p-0" v-if="idNhanh">
              <div class="alert-header" style="width: 100%">
                Phiếu được đồng bộ từ Nhanh:
                <span class="text-primary">{{ idNhanh }}</span>
              </div>
            </b-row>
            <b-row class="mb-5">
              <b-col lg="9" md="9" sm="12">
                <b-row>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group
                      id="input-group-1"
                      label-class="font-weight-bolder"
                      label-for="input-1"
                    >
                      <template>
                        <span>Mã phiếu:</span>
                      </template>
                      <p>{{ codeStockBill }}</p>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Ngày tạo:</span>
                      </template>
                      <p>{{ dateCreate }}</p>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Ngày nhập kho:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <p>{{ $v.form.dateImport.$model }}</p>
                      <!-- <date-picker
                        v-bind:disabled="status != 1"
                        placeholder="Chọn ngày nhập kho"
                        class="mb-2 mt-2 form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="$v.form.dateImport.$model"
                        :state="validateState('dateImport')"
                        aria-describedby="input-date-feedback"
                      ></date-picker>
                      <b-form-invalid-feedback id="input-live-feedback"
                        >Vui lòng chọn ngày nhập kho</b-form-invalid-feedback
                      > -->
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group
                      id="input-group-1"
                      label="Tổng tiền:"
                      label-for="input-1"
                    >
                      <p>{{ formatMoney(totalPrice) }}</p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Kho:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        id="input-1"
                        class="mt-2"
                        v-model="$v.form.inventorySelected.$model"
                        :options="listInventories"
                        size="sm"
                        required
                        disabled="true"
                        :state="validateState('inventorySelected')"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        @change="listProduct = []"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn kho</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback id="input-live-feedback"
                        >Vui lòng chọn kho</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Khách hàng:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <vue-autosuggest
                        class="border-radius-none mt-2"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptionsProvider"
                        @selected="onSelectedProvider"
                        :limit="200"
                        @input="onInputChangeProvider"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn khách hàng',
                          style: 'border-radius:0px!important',
                        }"
                        v-model="searchProvider"
                        :state="validateState('providerSelected')"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div>
                      </vue-autosuggest>
                      <b-form-invalid-feedback id="input-live-feedback"
                        >Vui lòng chọn nhà cung cấp</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Họ tên KH: </label>
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="customerName"
                        required
                        placeholder="Họ tên khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Địa chỉ:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="address"
                        required
                        placeholder="Địa chỉ khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="9" md="9" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Nội dung:</span>
                      </template>
                      <b-form-textarea
                        size="sm"
                        id="textarea1"
                        v-model="notes"
                        :placeholder="'Thêm nội dung...'"
                        :rows="3"
                        :max-rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group
                      id="input-group-1"
                      label-class="font-weight-bolder"
                      label-for="input-1"
                    >
                      <template>
                        <span>Người nhập:</span>
                      </template>
                      <p>{{ createBy }}</p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-select
                      id="input-1"
                      v-model="selectTypeSearch"
                      :options="listTypeSearch"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    ></b-form-select>
                  </b-col>
                  <b-col lg="9" md="9" sm="12">
                    <vue-autosuggest
                      v-model="searchProduct"
                      :suggestions="filteredOptionsProduct"
                      @selected="onSelectedProduct"
                      :limit="10"
                      @input="onInputChangProduct"
                      :input-props="{
                        id: 'autosuggest__input',
                        placeholder: 'Tìm kiếm',
                      }"
                      :should-render-suggestions="
                        (size, loading) =>
                          size >= 0 && !loading && searchProduct !== ''
                      "
                    >
                      <div
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <div style="{ display: 'flex', color: 'navyblue'}">
                          {{ suggestion.item.productName }}
                        </div>
                      </div>
                    </vue-autosuggest>
                  </b-col>
                </b-row>
              </b-col>

              <b-col lg="3" md="3" sm="12">
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền mặt</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="cashAmount"
                        required
                        placeholder="Nhập số tiền mặt"
                        v-mask="formater.currency"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group
                      id="input-group-type"
                      :label="'Tài khoản tiền mặt'"
                      label-for="select-accountant"
                    >
                      <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                      <vue-autosuggest
                        class="border-radius-none mt-2"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptionsCash"
                        @selected="onSelectedCash"
                        :limit="200"
                        @input="onInputChangeCash"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn tài khoản tiền mặt',
                          style: 'border-radius:0px!important',
                        }"
                        v-model="searchCash"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền chuyển khoản</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="transferAmount"
                        required
                        placeholder="Nhập số tiền"
                        v-mask="formater.currency"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group
                      id="input-group-type"
                      :label="'Tài khoản chuyển khoản'"
                      label-for="select-accountant"
                    >
                      <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                      <vue-autosuggest
                        class="border-radius-none mt-2"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptionsTransfer"
                        @selected="onSelectedTransfer"
                        :limit="200"
                        @input="onInputChangeTransfer"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn tài khoản chuyển khoản',
                          style: 'border-radius:0px!important',
                        }"
                        v-model="searchTransfer"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="stockSlipType !== 11">
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group
                      id="input-group-type"
                      :label="'Ngày thanh toán'"
                      label-for="select-paydate"
                    >
                      <date-picker
                        id="select-paydate"
                        placeholder="Chọn ngày thanh toán"
                        class="mb-2 mt-2 form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="payDate"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <span class="font-weight-bolder">Danh sách sản phẩm:</span>
            <table
              class="
                table table-bordered table-vertical-center table-hover
                col-12
                mt-4
              "
            >
              <thead>
                <tr>
                  <th scope="col" class="title-center" style="width: 15%">
                    Tên sản phẩm
                  </th>
                  <th scope="col" class="title-center" style="width: 5%">
                    Tồn
                  </th>
                  <th scope="col" class="title-center" style="width: 20%">
                    IMEI
                  </th>
                  <th scope="col" class="title-center" style="width: 10%">
                    Số lượng
                  </th>
                  <th scope="col" class="title-center" style="width: 18%">
                    Đơn giá
                  </th>
                  <th scope="col" class="title-center" style="width: 14%">
                    Thành tiền
                  </th>
                  <th scope="col" class="title-center" style="width: 13%">
                    Chiết khấu
                  </th>
                  <th scope="col" class="title-center" style="width: 5%"></th>
                </tr>
              </thead>

              <tbody v-for="item in listProductStock" :key="item.id">
                <ImportStock
                  v-bind:productItem="item"
                  v-bind:status="status"
                  v-bind:editState="true"
                  v-on:cancelAdd="cancelAdd"
                  v-on:save="save"
                  v-on:deleteItem="deleteItemInArray"
                  v-on:update="updateItem"
                />
              </tbody>
              <tr>
                <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
                <td class="tdTextAlignPrice">
                  {{ formatMoney(totalQuantity) }}
                </td>
                <td></td>
                <td class="tdTextAlignPrice">{{ formatMoney(totalPrice) }}</td>
                <td class="tdTextAlignPrice">
                  {{ formatMoney(totalDiscount) }}
                </td>
                <td></td>
              </tr>
            </table>

            <hr
              class="hr-text"
              data-content="Bút Toán"
              style="font-weight: 600"
            />
            <b-row v-if="codeStockBill !== ''">
              <b-col md="12" lg="12" sm="12">
                <StockSlipTransactions :stockCode="codeStockBill" />
              </b-col>
            </b-row>
            <b-modal
              v-model="showOriginalModel"
              hide-footer
              title="Thêm mới nhà cung cấp"
            >
              <b-row class="mb-5">
                <b-col>
                  <label class="labelInput" for="inputOriginal"
                    >Tên nhà cung cấp:</label
                  >
                  <b-form-input
                    v-model="providerName"
                    id="inputOriginal"
                    size="sm"
                    placeholder="Nhập tên nhà cung cấp"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <label class="labelInput" for="inputOriginal"
                    >Số điện thoại nhà cung cấp:</label
                  >
                  <b-form-input
                    v-model="providerPhone"
                    id="inputOriginal"
                    size="sm"
                    placeholder="Nhập số điện thoại nhà cung cấp"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="createProvider()"
                    >Lưu</b-button
                  >
                  <b-button
                    @click="showOriginalModel = !showOriginalModel"
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    >Hủy</b-button
                  >
                </b-col>
              </b-row>
            </b-modal>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="updateStock"
              >Lưu</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 100px"
              variant="primary"
              size="sm"
              @click="exportExcel"
              >Xuất Excel</b-button
            >
            <router-link to="/stocks" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}
</style>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.alert-header {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '../../components/ImportStock';
import StockSlipTransactions from '../../components/StockSlipTransactions';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { cmdUrl } from './../../../utils/apiUrl';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import axios from 'axios';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { BASE_URL, TIME_TRIGGER } from './../../../utils/constants';
import decounce from 'debounce';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      form: {
        inventorySelected: null,
        providerSelected: null,
        dateImport: '',
      },
      isNew: true,
      idStock: '',
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      listProviders: [],
      listProduct: [],
      count: 0,
      notes: '',
      createBy: '',
      status: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        // {
        //   id: 2,
        //   name: 'Tìm theo IMEI',
        // },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      editState: false,
      selectedClassProduct: null,
      timer: null,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      payDate: null,
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isSearching: false,
      idNhanh: null,
      stockSlipType: null,
      customerName: '',
      address: '',
    };
  },
  validations: {
    form: {
      inventorySelected: { required },
      providerSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    ImportStock,
    datePicker,
    StockSlipTransactions,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
  },
  created() {
    this.fetchAccountants('cash');
    this.fetchAccountants('transfer');
    this.fetchStore();
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu thu cũ', route: '/import-trade-in' },
      { title: 'Cập nhật phiếu thu cũ' },
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let quantity = 0;
      if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
      }
      this.count++;
      let pro = {
        id: this.count,
        name: this.selectedClassProduct.productName,
        totalQuantityInStock:
          this.selectedClassProduct.quantityInStock != null
            ? this.selectedClassProduct.quantityInStock
            : 0,
        IMEI: '',
        proId: this.selectedClassProduct.productId,
        price: this.selectedClassProduct.originalPrice,
        quantity: quantity,
        totalPrice: quantity * this.selectedClassProduct.originalPrice,
        productType: this.selectedClassProduct.productType,
        discount: 0,
      };
      if (this.selectedClassProduct.productType === 3) {
        this.makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      } else {
        this.listProductStock.push(pro);
      }
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getStockById() {
      let id = this.$route.query.id;
      ApiService.setHeader();
      ApiService.get(`stocks/${id}`)
        .then(({ data }) => {
          let dataRes = data.data;
          this.codeStockBill = dataRes.code;
          this.dateCreate = moment(String(dataRes.createdAt)).format(
            'HH:mm DD/MM/YYYY'
          );
          this.$v.form.dateImport.$model = moment(
            String(dataRes.importDate)
          ).format('DD-MM-YYYY');
          this.$v.form.inventorySelected.$model = dataRes.storeId;
          this.$v.form.providerSelected.$model = dataRes.providerId;
          this.searchProvider = dataRes.customerPhone;
          this.customerName = dataRes.providerName;
          this.address = dataRes.address;
          this.totalPrice = dataRes.totalAmount;
          this.notes = dataRes.description;
          this.createBy = dataRes.createdBy;
          this.status = dataRes.status;
          this.idStock = dataRes.id;
          this.cashAmount = dataRes.cashAmount;
          this.cashAccountCode = dataRes.cashAccountCode;
          this.searchCash = dataRes.cashAccName;
          this.transferAmount = dataRes.transferAmount;
          this.transferAccountCode = dataRes.transferAccountCode;
          this.searchTransfer = dataRes.transferAccName;
          this.payDate = moment(String(dataRes.payDate)).format('DD-MM-YYYY');
          this.idNhanh = dataRes.idNhanh;
          this.stockSlipType = dataRes.type;
          this.listProductStock = [];
          dataRes.listDetail.forEach((element) => {
            this.count++;
            let listImei = element.productImei.split(',');
            let stt = 1;
            let formatImei = '';
            listImei.forEach((element, index) => {
              formatImei += stt + ')  ' + element;
              if (index !== listImei.length - 1) {
                formatImei += ',';
              }
              stt++;
            });
            let dataItem = {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              formatImei: formatImei.replace(/[,]/g, '\n').replace(/[ ]/g, ''),
              totalQuantityInStock:
                element.totalQuantityInStock != null
                  ? element.totalQuantityInStock
                  : 0,
              discount: element.discount,
            };
            this.listProductStock.push(dataItem);
          });
          this.fetchAccountants('cash');
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      let data = {
        productId: item.proId,
        stockSlipId: this.idStock,
        productImei: item.IMEI,
        quantity: item.quantity,
        unitPrice: item.price,
      };
      ApiService.setHeader();
      ApiService.post('stocks/create-stock-detail', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listProduct = [];
            //this.getStockById();
            this.makeToastSuccess(data.message);
            this.getListDetailStock();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.setHeader();
      ApiService.get(
        `productSearch?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.listProviders.map((element) => {
          let provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let tmp = [{ data: this.optionsProvider[0].data }];
        this.filteredOptionsProvider = tmp;
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].totalPrice = item.totalPrice;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
        }
      });
    },
    getListDetailStock() {
      ApiService.setHeader();
      ApiService.get(`stocks/list-stock-detail/${this.idStock}`).then(
        ({ data }) => {
          data.data.forEach((element) => {
            let dataItem = {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
            };
            this.listProduct.push(dataItem);
          });
        }
      );
    },
    updateStock: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let listPro = [];
      await this.listProductStock.forEach((element) => {
        let item = {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
        };
        listPro.push(item);
      });
      let data = {
        code: this.codeStockBill,
        id: this.idStock,
        storeId: this.$v.form.inventorySelected.$model,
        providerId: this.$v.form.providerSelected.$model,
        description: this.notes,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD'
            )
          : '',
        listProduct: listPro,
        cashAmount: unMaskPrice(this.cashAmount),
        cashAccountCode: this.cashAccountCode,
        transferAmount: unMaskPrice(this.transferAmount),
        transferAccountCode: this.transferAccountCode,
        payDate: this.payDate
          ? moment(this.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
      };
      ApiService.setHeader();
      ApiService.post('stocks/update-import-stock', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listProduct = [];
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/import-trade-in',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateStockComplete: async function () {
      ApiService.setHeader();
      ApiService.post(`stocks/complete-import-stock/${this.idStock}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listProduct = [];
            this.getStockById();
            this.makeToastSuccess(data.message);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchProductImei() {
      this.listProduct = [];
      ApiService.setHeader();
      ApiService.get(`stocks/get-product-by-store-imei`).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
      });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.$v.form.inventorySelected.$model !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.$v.form.inventorySelected.$model,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        let tmpCash = [{ data: this.optionsCash[0].data }];
        this.filteredOptionsCash = tmpCash;
        let tmpTransfer = [{ data: this.optionsTransfer[0].data }];
        this.filteredOptionsTransfer = tmpTransfer;
      });
    },
    exportExcel: async function () {
      ApiService.setHeader();
      let baseUrl = BASE_URL;
      //let baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}stocks/export-excel/${this.idStock}?type=1`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // let fileName = 'Hóa đơn nhập NCC';
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    createProvider: async function () {
      if (!this.providerName) {
        return;
      }
      let provider = {
        name: this.providerName,
        phoneNo: this.providerPhone,
        status: 1,
      };

      let paramQuery = {
        params: provider,
      };

      ApiService.setHeader();
      ApiService.post('providers', paramQuery).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          this.makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          this.makeToastFaile(message);
        }
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.suggestionName;
      this.$v.form.providerSelected.$model = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      if (this.stockSlipType === 11) {
        this.debounceInput();
      } else {
        const filteredData = this.optionsProvider[0].data
          .filter((item) => {
            return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
          })
          .slice(0, this.limit);
        this.filteredOptionsProvider = [
          {
            data: filteredData,
          },
        ];
      }
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [
        {
          data: filteredData,
        },
      ];
    },
    debounceInputProduct: decounce(function () {
      this.nextSearchProduct();
    }, TIME_TRIGGER),
    nextSearchProduct() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    getListCustomer() {
      this.optionsProvider[0].data = [];
      let text = this.searchProvider;
      ApiService.setHeader();
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          customers.map((element) => {
            let cus = {
              id: element.id,
              name: element.fullName,
              phoneNo: element.phoneNo,
              suggestionName: element.phoneNo + '- ' + element.fullName,
            };
            this.optionsProvider[0].data.push(cus);
          });
          this.filteredOptionsProvider = [
            {
              data: this.optionsProvider[0].data,
            },
          ];
        }
      );
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
  },
};
</script>
